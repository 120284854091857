/* App.css */

.app {
  text-align: center;
  height: 100vh;
  margin: 0;
  background-color: #000; /* Set app background to black */
  color: #fff; /* Set default text color to white */
  background-image: 
    linear-gradient(to right, rgba(128, 128, 128, 0.2) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(128, 128, 128, 0.2) 1px, transparent 1px);
  background-size: 20px 20px;
}

.welcome-screen {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
}

/* Frequency timer at the bottom */
.frequency-timer {
  position: fixed;
  bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 2em;
  color: #fff;
}

h1{
  font-size: 4em;
}
@font-face {
  
  font-family: 'logo';
  src: url('./fonts/Virgil.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.mobile-message {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  background-color: #f5f5f5;
  background-image: 
    linear-gradient(to right, rgba(128, 128, 128, 0.2) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(128, 128, 128, 0.2) 1px, transparent 1px);
  background-size: 20px 20px;
}

.mobile-message h1 {
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  text-align: left;
}

.mobile-message p {
  color: #666;
  margin: 0.5rem 0;
  font-size: 1.1rem;
  line-height: 1.5;
  text-align: left;
}

/* Optional: Add some animation */
.mobile-message {
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(220, 38, 38, 0.95); /* Red background with slight transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: transparent;
  padding: 2rem;
  text-align: center;
  color: white;
}

.modal-buttons {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
}

.modal-buttons button {
  padding: 1rem 2rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.5rem;
  border: none;
  transition: transform 0.2s;
}

.modal-buttons button:hover {
  transform: scale(1.05);
}

/* Style the Begin button like "Start now" */
.modal-buttons button:last-child {
  background-color: white;
  color: rgb(220, 38, 38);
  font-weight: bold;
}

/* Style the Skip button */
.modal-buttons button:first-child {
  background-color: transparent;
  color: white;
  font-weight: bold;
}