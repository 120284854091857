/* FrequencyTimer.css */

.frequency-timer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: red; /* Full-width red background */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    color: white;
  }
  
  .timer-text {
    font-size: 2em;
    margin: 0;
  }
  
  .or-text {
    font-size: 1.5em;
    margin: 0 10px;
  }
  
  .start-now-button {
    padding: 10px 20px;
    margin-left: 7px;
    font-size: 1.5em;
    cursor: pointer;
    background-color: white;
    color: red;
    border: none;
    border-radius: 5px;
  }
  
  .start-now-button:hover {
    background-color: #f2f2f2;
  }
  