/* CircularControl.css */

.circular-control {
    position: relative;
    width: 600px; /* Increased size */
    height: 300px; /* Increased size */
    margin: 50px auto;
  }
  
  /* Outer ring - unused */
  .outer-ring {
    position: relative;
    width: 100%;
    height: 100%;
    border: 10px solid #fff; 
    border-radius: 50%;
    box-sizing: border-box;
  }
  
  .adjustment-buttons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .adjustment-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff; /* Text color to white */
  }
  
  .adjustment-section button {
    width: 50px; /* Increased size */
    height: 50px; /* Increased size */
    font-size: 2em;
    color: #fff;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 50%;
  }
  
  .adjustment-section h2,
  .adjustment-section p {
    margin: 5px 0;
    color: #fff;
  }
  
  .inner-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px; /* Increased size */
    height: 250px; /* Increased size */
    background-color: red; /* Inner circle background black */
    border: 5px solid #fff; /* Added border */
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .inner-circle h2 {
    margin: 0;
    font-size: 3em;
    color: #fff;
  }
  
  .inner-circle p {
    margin: 5px 0;
    color: #fff;
  }
  
  .start-session-button {
    margin-top: 10px;
    padding: 15px 30px;
    font-size: 1.5em;
    cursor: pointer;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 5px;
  }
  
  .start-session-button:hover {
    background-color: #ddd;
  }
  