/* PoseGame.css */

.pose-game-container {
    display: flex;
    height: 100vh;
  }
  
  .video-container {
    flex: 1;
    position: relative;
  }
  
  .instruction-container {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    overflow-y: auto;

  }
  
  .canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .balls-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
  }
  
  /* Instructions styling */
  .instructions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .instruction-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .instruction-ball {
    width: 60px; /* Adjust size as needed */
    height: 60px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .instructions h2 {
    font-size: 4em;
    margin: 0;
    text-align: center;
    color: black;
  }
  
  /* Timer styles */
  .timer-container {
    position: relative;
    width: 100%;
    background-color: red;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  
  .timer {
    font-size: 3em;
    margin: 0;
  }
  